import React, { useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Home from "@material-ui/icons/Home";
import Apps from "@material-ui/icons/Apps";
import ContactMail from "@material-ui/icons/ContactMail";
import { makeStyles } from "@material-ui/core/styles";
import avatar from "../avatar.png";

import Footer from "../components/Footer";
import {Chat, GitHub} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: "#222",
    margin: 0,
  },
  arrow: {
    color: "tomato",
    marginRight: 10
  },
  title: {
    color: "tan",
  },
  menuSliderContainer: {
    width: 250,
    background: "#511",
    height: "100%",
  },
  avatar: {
    display: "block",
    margin: "0.5rem auto",
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
  listItem: {
    color: "tan",
  },
}));

const menuItems = [
  { listIcon: <Home />, listText: "Home", listPath: "/", isNew: false },
  { listIcon: <AssignmentInd />, listText: "History", listPath: "/resume", isNew: false },
  { listIcon: <Apps />, listText: "Portfolio", listPath: "/portfolio", isNew: false },
  { listIcon: <GitHub />, listText: "GitHub", listPath: "https://github.com/serhiis0223", isNew: true },
  // { listIcon: <ContactMail />, listText: "Contact", listPath: "mailto:semenser221@outlook.com", isNew: true },
  { listIcon: <Chat />, listText: "Hire Me", listPath: "https://www.upwork.com/freelancers/~0192ba2ef7bc20fec3", isNew: true },
];

const Navbar = (props) => {
  // const [open, setOpen] = useState(false);

  const classes = useStyles();

  const sideList = () => (
    <Box className={classes.menuSliderContainer} component="div">
      <Avatar className={classes.avatar} src={avatar} alt="Serhii Semeniaka" />
      <Divider />
      <List>
        {menuItems.map((item, i) => (
          <ListItem
            button
            key={i}
            className={classes.listItem}
            onClick={() => props.setOpen(false)}
            component={item.isNew ? 'a' : Link}
            target={item.isNew ? '_blank' : '_self'}
            to={item.listPath}
            href={item.listPath}
          >
            <ListItemIcon className={classes.listItem}>
              {item.listIcon}
            </ListItemIcon>
            <ListItemText primary={item.listText} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Box component="nav">
        <AppBar position="static" className={classes.appbar}>
          <Toolbar>
            <IconButton onClick={() => props.setOpen(true)}>
              <ArrowBack className={classes.arrow} />

              <Typography variant="h5" className={classes.title}>
                Menu
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer open={props.open} anchor="right" onClose={() => props.setOpen(false)}>
        {sideList()}
        {/*<Footer />*/}
      </Drawer>
    </React.Fragment>
  );
};

export default Navbar;
